body {
  margin: 0;
}

button {
  border-radius: 50px !important;
}

.MuiInputBase-root {
  border-radius: 50px !important;
}

.MuiButton-root {
  border-radius: 50px !important;
}
